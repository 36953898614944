import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Experience"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="../work/creditKarmaLogo.png"
								alt="upstate logo"
								className="work-image"
							/>
							<div className="work-title">Credit Karma</div>
							<div className="work-subtitle">
								Software Engineer Intern
							</div>
							<div className="work-duration">May 2024 - Aug 2024</div>
						</div>

						<div className="work">
							<img
								src="https://static.vecteezy.com/system/resources/previews/014/179/898/original/cartoon-grass-transparent-background-free-png.png"
								alt="logo"
								className="work-image"
							/>
							<div className="work-title">Landscaping</div>
							<div className="work-subtitle">
								Self-Employed
							</div>
							<div className="work-duration">2016 - 2023</div>
						</div>

						<div className="work">
							<img
								src="../work/upstateLogo.png"
								alt="upstate logo"
								className="work-image"
							/>
							<div className="work-title">Upstate Pool Management Company</div>
							<div className="work-subtitle">
								Head Lifeguard
							</div>
							<div className="work-duration">2021 - 2022</div>
						</div>

						<div className="work">
							<img
								src="../work/chickfilaLogo.png"
								alt="chick-fil-a logo"
								className="work-image"
							/>
							<div className="work-title">Chick-Fil-A</div>
							<div className="work-subtitle">
								Hospitality Specialist
							</div>
							<div className="work-duration">2020 - 2021</div>
						</div>

						
					</div>
				}
			/>
		</div>
	);
};

export default Works;
