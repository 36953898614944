import React from "react";

function tigerPark() {
	return {
		codeLink: 'https://github.com/BrandonMarks6/TigerPark',
		coverImage: "../hackathon/homepage2023.jpeg",
		date: "February 2023",
		title: "TigerPark",
		description:
			"My team built a parking reservation web app called TigerPark in 24 hours using React, Typescript, and AWS to allow Clemson students to conveniently book campus parking spots online. I gained valuable full-stack experience creating the backend APIs and database while collaborating with teammates on the React frontend. This hackathon highlighted the importance of continuous testing, communication, and documentation for delivering an integrated product under a tight deadline.",
			style: `
			.article-content {
				display: flex;
				flex-direction: column;
				align-items: center;
			}


			.randImage {
				width: 50%;
				align-self: center;
			}
			`,
		keywords: [
		],
		body: (
			<React.Fragment>
			<div className="article-content">
				<div className="paragraph"><p>For a 24 hour hackathon, my teammates and I decided to build a website prototype for reserving parking spaces around Clemson's campus. Parking is a huge headache for students at Clemson, so we wanted to create a solution.</p><p>Our app TigerPark lets people book parking spots online instead of circling endlessly and hoping to get lucky. We used JavaScript, React, Node.js, Typescript and AWS to build it.</p><p>We separated into two groups to work on the front end and back end separately. I worked with a teammate building the backend with javascript and Node.js. We utilized Postman to continuously validate and test endpoint throughout development</p><p>It was awesome to see our hard work pay off when we successfully demoed a finished product by the deadline, even after hitting some roadblocks. This hackathon really showed me how important communication and detailed documentation is for smooth integration between frontend and backend systems.</p><p>I'm proud my team created something practical that could make a real difference on campus. Coding an entire parking app in 24 hours was an amazing challenge. The hands-on experience solidified my passion for finding solutions through programming.</p></div>
				<img
						src="../hackathon/homepage2023.jpeg"
						alt="random"
						className="randImage"
					/>
					<p></p>
				<img
						src="../hackathon/viewpage2023.jpeg"
						alt="group"
						className="randImage"
					/>
			</div>
			</React.Fragment>
		),
	};
}

function wingman() {
	return {
		codeLink: 'https://github.com/BrandonMarks6/wingmanProject',
		coverImage: "../hackathon/award2022.jpg",
		date: "October 2022",
		title: "Wingman",
		description:
			"At the freshman Hello World hackathon, my team built a dating app with a unique friend-powered matching feature using new-to-me technologies like Firebase, React, and TypeScript. Pushing beyond my coding comfort zone taught me invaluable lessons, ultimately leading our app to win Best Web App despite our inexperience. This hackathon ignited my passion for creating technology and motivated me to continue honing my skills at future events.",
			style: `
			.article-content {
				display: flex;
				flex-direction: column;
				align-items: center;
			}


			.randImage {
				width: 50%;
				align-self: center;
			}
			`,
		keywords: [
		],
		body: (
			<React.Fragment>
				<div className="article-content">
				<div className="paragraph"><p>My team and I had a blast at the Hello World 2022 hackathon for freshman at Clemson. We built a dating app with a fun twist - your friends can help pick your matches. We thought it would be cool to turn the idea of matchmaking into an app feature.</p><p>The tech stack was Firebase, JavaScript, TypeScript and React - all new to me. The first few hours were slow going as I struggled to learn on the fly. Pushing past my discomfort to experiment with unfamiliar skills was tough but incredibly rewarding.</p><p>Stepping outside my comfort zone taught me so much and helped me make connections with other passionate developers. Our app won Best Web App, which made me proud of what we achieved despite limited experience.</p><p>This hackathon gave me inspiration and drive to keep creating, learning and improving. Working alongside my team to build something from scratch showed me how exciting and fulfilling development can be.</p><p>Even when the tech felt daunting, I'm glad I embraced the challenge. The hands-on practice solidified fundamental skills I'll build on for years to come.</p></div>
				<img
						src="../hackathon/award2022.jpg"
						alt="random"
						className="randImage"
					/>
					<p></p>
				<img
						src="../hackathon/presentation2022.jpg"
						alt="group"
						className="randImage"
					/>
			</div>
			</React.Fragment>
		),
	};
}

function planimals() {
	return {
		codeLink: 'https://github.com/BrandonMarks6/Planimals',
		coverImage: "../hackathon/Directing2024.png",
		date: "March 2024",
		title: "Planimals",
		description:
			"At the CU-Hackit 2024, my team developed Planimals, a unique take on the to-do list concept featuring a virtual pet dependency system. Utilizing AWS Lambda, API Gateway, DynamoDB, and Node.js for the backend, and HTML, CSS, and JavaScript for the frontend, we created an engaging web application. Leading a team with varying levels of experience, I navigated challenges and facilitated a collaborative learning environment. Despite initial hurdles, our strategic choices and dedication resulted in a successful project and a great experience.",
			style: `
			.article-content {
				display: flex;
				flex-direction: column;
				align-items: center;
			}


			.randImage {
				width: 50%;
				align-self: center;
			}
			`,
		keywords: [
		],
		body: (
			<React.Fragment>
				<div className="article-content">
				<div className="paragraph"><p>Planimals, emerged from a hackathon setting where our team aimed to craft a full-stack web application with a twist on conventional to-do lists. We sought to integrate a playful element by associating tasks with the care of a virtual "pet," adding a layer of accountability and engagement to task completion.

				</p><p>The endeavor was particularly challenging yet enriching as it marked my first experience in a hackathon where I assumed a leadership role due to my relatively higher experience level within the team. This leadership responsibility allowed me to make pivotal decisions regarding project direction and architecture, giving me first person experience with the significance of early-stage planning in such endeavors.

				</p><p>One unexpected hurdle we encountered was the significant time investment required to familiarize the team with the new technologies and languages essential for web development. Other than myself, the team had little to no web development experience. However, this challenge served as an opportunity for me to leverage my experience to facilitate learning among team members, thereby reinforcing my own understanding of these concepts.

				</p><p>Despite the initial learning curve, our team effectively leveraged AWS Lambda, API Gateway, DynamoDB, and Node.js for the backend, complemented by HTML, CSS, and JavaScript for the frontend. These choices were deliberate, as they offered flexibility, scalability, and customization options essential for our project's unique requirements, showcasing our ability to make informed and strategic technology decisions.

				</p><p>Ultimately, the hackathon concluded with a tangible product that not only met our initial vision but also enriched our collective understanding of web development. Personally, I gained valuable insights into leadership, teamwork, and the art of effectively teaching others while working alongside them.</p></div>
				<img
						src="../hackathon/Listening2024.png"
						alt="random"
						className="randImage"
					/>
					<p></p>
				<img
						src="../hackathon/Pointing2024.png"
						alt="random"
						className="randImage"
					/>
					<p></p>
				<img
						src="../hackathon/Directing2024.png"
						alt="random"
						className="randImage"
					/>
					<p></p>

			</div>
			</React.Fragment>
		),
	};
}
const myArticles = [planimals, tigerPark, wingman];

export default myArticles;
