const INFO = {
	main: {
		title: "Brandon's Portfolio",
		name: "Brandon Marks",
		email: "brandonmarkssc@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/brandonmarks6",
		linkedin: "https://linkedin.com/in/brandonmarks6/",
		instagram: "https://instagram.com/brandon.dmarks",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Brandon Marks - Software Engineer",
		description:
			"I am a software engineer with an interest in backend web development. I have experince with agile practices, web development, and a variety of different technologies and laguages. I enjoy seing my ideas come together to solve or simplify something that intially seemed complex. I am passionate about furthering myself as a developer while contributing to large scale projects effectively.",
	},

	about: {
		title: "I’m Brandon Marks. I love seeing the ideas in my head come to life through my code.",
		description:
			"I am a software engineer currently attending Clemson University for Computer Science. I am motivated by making real life solutions through my code. I especially enjoy backend systems and web development, but I am open to any opportunity. My passion for software engineering comes from the fact that I can directly implement the ideas in my head through my code. Two of my proudest moments so far are winning “Best Web App” at HelloWorld 2022 Hackathon and the chance to watch my minesweeper bot play the game for hours at a time without my interaction. My goal is to further explore my passion for software engineering while making peoples lives better though it.",
	}, 

	articles: {
		title: "Hackathon Projects",
		description:
			"I'm passionate about pushing myself to learn more while gaining expereince by working with new people and experimenting with new projects.",
	},

	projects: [
		{
			title: "DropDetect",
			description:
				"An Chrome Extension that leverages Tensorflow to predict whether or not a site is a dropshipping front",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png",
			linkText: "Source Code",
			link: "https://github.com/BrandonMarks6/DropDetect",
			starred: "Yes",
			star: "https://i.pinimg.com/originals/e8/91/66/e891669c27c833ff0f2db2c083344117.png",
		},

		{
			title: "ApartmentRatings",
			description:
				"A RESTful API build to collect and distribute information in relation to apartments",
			logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
			linkText: "Source Code",
			link: "https://github.com/BrandonMarks6/ApartmentRatings",
			starred: "Yes",
			star: "https://i.pinimg.com/originals/e8/91/66/e891669c27c833ff0f2db2c083344117.png",
		},

		{
			title: "MinesweeperBot",
			description:
				"An image recognition program which uses OpenCV and Pyautogui in order to play google minesweeper at fast speeds",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "Source Code",
			link: "https://github.com/BrandonMarks6/MinesweeperBot",
			starred: "Yes",
			star: "https://i.pinimg.com/originals/e8/91/66/e891669c27c833ff0f2db2c083344117.png",
		},

		{
			title: "ClashClansTrophy",
			description:
				"An starlark app to display player trophy count in Clash of Clans on a Tidbyt™ device",
			logo: "https://assets-global.website-files.com/5d490ccd3cf49e0bd0ec972e/5e2adb2e718fe0c91690bd60_Skylark%20Webclip.png",
			link: "https://github.com/tidbyt/community/tree/main/apps/clashclanstrophy",
			linkText: "Source code",
			starred: "No",
			star: "https://i.pinimg.com/originals/e8/91/66/e891669c27c833ff0f2db2c083344117.png",

		},

		{
			title: "WeatherAlerts",
			description:
				"A weather information program which uses multiple API's in order to collect weather data and distribute it through text messaging to consumers",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			link: "https://github.com/BrandonMarks6/WeatherAlerts",
			linkText: "Source Code",
			starred: "No",
			star: "https://i.pinimg.com/originals/e8/91/66/e891669c27c833ff0f2db2c083344117.png",
		},

		{
			title: "Connect X",
			description:
				"A scalable Connect X game implemented using the full software development lifecycle",
			logo: "https://cdn.icon-icons.com/icons2/2699/PNG/512/java_logo_icon_169577.png",
			linkText: "Source code avaliable upon request",
			starred: "No",
			star: "https://i.pinimg.com/originals/e8/91/66/e891669c27c833ff0f2db2c083344117.png",

		},

		

		
	],
};

export default INFO;
