import React from "react";

import Project from "./project";

import INFO from "../../data/user";

import "./styles/allProjects.css";

const SideProjects = () => {
	return (
		<div className="all-projects-container">
			{INFO.projects.filter((project) => project.starred === "No").map((project, index) => (
				<div className="all-projects-project" key={index}>
					<Project
						logo={project.logo}
						title={project.title}
						description={project.description}
						linkText={project.linkText}
						link={project.link}	
						starred={project.starred}
						star={project.star}					
					/>
				</div>
			))}
		</div>
	);
};

export default SideProjects;
