import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo"; // Add your logo component
import Footer from "../components/common/footer"; // Add your footer component
import NavBar from "../components/common/navBar"; // Add your navbar component

import INFO from "../data/user"; // Your data for title, description, socials
import "./styles/experience.css"; // Updated to match the new CSS file

const JobExperience = ({ position, company, companyPage, date, description, coverPhoto }) => (
    <div className="job-experience">
        <img src={coverPhoto} alt={`${company} logo`} className="cover-photo" />
        <h3>{position} - {company}</h3>
        <p>{date}</p>
        <ul>
            {description.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>


    </div>
);



const Experience = () => {
	// eslint-disable-next-line
	const [stayLogo, setStayLogo] = useState(false); 
	// eslint-disable-next-line
	const [logoSize, setLogoSize] = useState(80); 
	
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            let scroll = Math.round(window.pageYOffset, 2);
            let newLogoSize = 80 - (scroll * 4) / 10;

            if (newLogoSize < 40) {
                setLogoSize(40);
                setStayLogo(true);
            } else {
                setLogoSize(newLogoSize);
                setStayLogo(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

	const experiences = [
		{
			position: "Software Engineer Intern",
			company: "Credit Karma",
			companyPage: "https://www.creditkarma.com", // Link to the company page
			date: "May 2024 - August 2024",
			description: [
				"Created user engagement features to enhance scoring in recommendation models, impacting over 1 billion daily predictions",
				"Implemented column level lineage to an internal tables lineage SDK, enabling custom upstream and downstream alerting",
				"Conducted research and contributed to optimization efforts resulting in improvements of up to 8x for 100+ Looker alerts",
				"Integrated LLM chatbot intent classification into recommendation models, enhancing system precision and user insights"
			],
			coverPhoto: "/work/creditKarmaLogo.png"
		},
		{
			position: "Lead Software Engineer",
			company: "Clemson Fintech",
			companyPage: "https://www.clemsonfintech.com", // Link to the company page
			date: "June 2024 - Present",
			description: [
				"Leading foundational efforts in the inaugural year, establishing key processes and ideating the club's initial fintech projects",
				"Managing a team of 10+ developers in gaining experience with financial technology related projects",
				"Fostering a fast-moving experimentation-driven environment with various different tools in order to gain experience",
				"Mentoring younger team members in order to help them perform at their highest potential"
			],
			coverPhoto: "/work/clemsonFintech.png"
		},
	];
	


    return (
		<React.Fragment>
		<Helmet>
				<title>{INFO.main.title}</title>

		</Helmet>
		

        <div className="page-content">
			<NavBar active="experience" />
            <div className="content-wrapper">
                <div className="experience-logo-container">
					<div className="contact-logo">
							<Logo width={46} />
						</div>
                </div>

				<div className="experience-container">
					<div className="title experience-title">
						Professional Experience
					</div>

					<div className="subtitle contact-subtitle">
					This page highlights my software engineering work, from internships at leading companies to leadership roles in university clubs. It showcases my hands-on experience in backend development, data-driven projects, and collaborative tech initiatives.
					</div>
					<div className="experience-socials">
						<a href={INFO.socials.github} target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faGithub} className="experience-social-icon" />
						</a>
						<a href={INFO.socials.linkedin} target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faLinkedin} className="experience-social-icon" />
						</a>
						<a href={`mailto:${INFO.main.email}`} target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faMailBulk} className="experience-social-icon" />
						</a>
					</div>

					<div className="experience-section">
						{experiences.map((job, index) => (
							<JobExperience
								key={index}
								position={job.position}
								company={job.company}
								companyPage={job.companyPage}  
								date={job.date}
								description={job.description}
								coverPhoto={job.coverPhoto}
							/>
						))}
					</div>
				</div>

                <Footer />
            </div>
        </div>
		</React.Fragment>
    );
};

export default Experience
